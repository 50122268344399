.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p {
  @apply text-white;
}

.terms p {
  @apply text-black;
}

.toolbar_card {
  @apply flex flex-col px-8 py-8 my-4 rounded-lg shadow;
}

.toolbar_header_container {
  @apply flex mb-8;
}

.toolbar_header {
  @apply text-xs leading-none leading-loose tracking-widest text-gray-500 uppercase;
}

.toolbar-icon {
  @apply w-6 h-6 mr-2;
}

.nav-link {
  @apply relative flex items-center justify-center flex-1 text-xl font-black text-white uppercase cursor-pointer;
}

.nav-link-background {
  @apply flex justify-center flex-1 w-1/2 h-full;
}

.nav-link:focus {
  @apply text-white bg-gray-700 outline-none;
}

.nav-link:hover {
  @apply text-white bg-phoenix-gray-light;
}

.nav-link a {
  @apply flex items-center h-full px-12 py-8;
}

@screen sm {
  .nav-link a {
    @apply px-3 py-2 text-lg;
  }

  .nav-link.active a {
    @apply pb-1;
  }

  .nav-link.active {
    @apply border-b-4 border-phoenix-red;
  }
}

.container {
  @apply w-11/12 h-full m-auto;
}

.btn {
  @apply relative w-full py-4 font-black tracking-wider text-white uppercase rounded bg-phoenix-red;
}

.input {
  @apply w-full border-b-2 rounded rounded-t-none border-phoenix-red;
}

.input-wrapper {
  @apply border border-gray-300 rounded;
}

.account-card {
  @apply py-6 border px-7 border-phoenix-gray-light bg-phoenix-gray-dark;
}

.card-title {
  @apply text-3xl font-black leading-none text-white uppercase;
}

@screen sm {
  .account-card {
    @apply rounded;
  }
}

ul.checkmark li {
  margin-bottom: 0.25rem;
  list-style-type: none;
  padding: 0 0 0 1.5em;
  position: relative;
}
ul.checkmark li:after {
  content: ' ';
  display: block;
  width: 0.3em;
  height: 0.6em;
  border: solid #b1101b;
  border-width: 0 0.2em 0.2em 0;
  position: absolute;
  left: 0;
  top: 40%;
  margin-top: -0.2em;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.additional-features-list li {
  @apply flex items-center py-2;
}
